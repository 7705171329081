//import logo from './img/logo.png';
import Icon from "./components/Icons";

function App() {
    let logoURL = "./logo.png";

    return (
        <div className="container mx-0 md:mx-auto flex flex-col items-center justify-center w-full h-full gap-24">
            <header className="flex justify-center flex-none w-full">
                <div className="">
                    <img src={logoURL} width="650" height="650" className="m-auto w-2/5 max-w-[650px]" alt="logo" />
                </div>
            </header>
            <div className="flex flex-col items-center justify-between w-fit">
                <div className="z-50 flex flex-row gap-8 px-10 md:gap-16 md:px-24 justify-center">
                    <Icon name="envelope" desc="ileaf email" color="cyan" url="mailto:luis.arango@ileaf.co.nz" />
                    {/* <Icon name={["fab",'whatsapp']} desc="ileaf whatsapp" color="whatsapp" url="https://api.whatsapp.com/send?phone=64277556692&text=&source=&data=&app_absent="/>
          <Icon name={["fab",'linkedin']} desc="ileaf linkedin" color="linkedin" url="https://www.linkedin.com/in/learangof/"/>
          <Icon name={["fab",'github']} desc="ileaf github" color="github" url="https://github.com/learangof"/> */}
                </div>
                <div className="z-10 block w-10/12 h-8 -mt-4 opacity-25 blur-lg rounded-2xl bg-gradient-to-b from-black via-gray-400 to-black"></div>
                <div className="z-10 block w-full h-10 blur-md -mt-9 rounded-2xl bg-gradient-to-r opacity-30 from-black via-gray-400 to-black"></div>
            </div>
        </div>
    );
}

export default App;
