import { library } from '@fortawesome/fontawesome-svg-core'
import { faWhatsapp, faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

library.add(
    faWhatsapp,
    faLinkedin,
    faGithub,
    faEnvelope
);

function Icon({name,color,url,desc}){
    let colors = {
        'cyan': "hover:shadow-cyan-500/50 bg-cyan-500",
        'whatsapp': "hover:shadow-[#25D366]/50 bg-[#25D366]",
        'linkedin': "hover:shadow-[#0A66C2]/50 bg-[#0A66C2]",
        'github': "hover:shadow-[#333333]/50 bg-[#333333]",
    }
    return(
        <>
            <a aria-label={(desc)? desc : 'link'} href={url} target="_blank"
                className={`${colors[color]} text-center w-1/6 min-w-[80px] max-w-[95px] max-h-[50px] shadow-lg py-1 px-2 md:py-2 md:px-5 rounded-md`} rel="noreferrer">
                    <FontAwesomeIcon className="" icon={name} size="2x" />
            </a>
        </>
    );
}
export default Icon;
